import React from "react";
import "./styles.css";
import GaugeChart from "react-gauge-chart";
import { IonCard, IonText } from "@ionic/react";
import { Text } from "@primer/react";
import BatteryGauge from "react-battery-gauge";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
const TemperatureCard = ({ ble, color, percent, gray }: any) => {
  const { temperature, mac, name, humidity, battery, updatedAt } = ble;

  return (
    <div className="temperature-card">
      <div style={{ textAlign: "center" }}>
        <Text
          as="p"
          sx={{ color: "fg.onEmphasis", bg: "neutral.emphasis", p: 2 }}
        >
          {" "}
          {mac}
        </Text>{" "}
        {name && (
          <Text as="p" sx={{ color: "#333", bg: "#cecece", p: 2 }}>
            {name}
          </Text>
        )}
        <BatteryGauge
          customization={{
            batteryCap: { fill: "#22A7F0", strokeColor: "#22A7F0" },
            batteryMeter: { fill: "#22A7F0" },
            batteryBody: { strokeColor: "#22A7F0" },
          }}
          size={50}
          value={battery}
        />
        <div
          // className="ave_temp_txt ave temperature"
          style={{ color: color, fontSize: "60px", fontWeight: "500" }}
        >
          {temperature}°C
        </div>
        <div className="flex flex-row	align-items-center justify-content-center p-3">
          <span className="flex flex-row	align-items-center justify-content-between">
            <i
              className="pi pi-cloud"
              style={{ fontSize: "1rem", color: "#22A7F0" }}
            ></i>
            <p className="last-updated px-3">Humidity: </p>
          </span>
          <p className="text-sm	font-semibold		" style={{ lineHeight: 0 }}>
            {humidity}
          </p>
        </div>
        <div className="flex flex-row	align-items-center justify-content-between flex-column	 px-3 ">
          <p className="last-updated">Last Updated</p>
          <p style={{ lineHeight: 0 }}>
            {new Date(updatedAt).toLocaleString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>
        </div>
      </div>
      <div className="gauge_style">
        <GaugeChart
          id="gauge-chart1"
          textColor={"#FFC371"}
          style={{ height: "100px" }}
          hideText={true}
          colors={["#88c7dc", "#FFC371", "#d12121"]}
          arcWidth={0.2}
          percent={percent}
          // percent={
          //   parseFloat(
          //     (temperature / tfg_data.max_temperature).toFixed(2)
          //   ) > 1
          //     ? 1
          //     : parseFloat(
          //         (curr_temps.E1CC3CE4FBA8 / tfg_data.max_temperature).toFixed(
          //           2
          //         )
          //       )
          // }
          nrOfLevels={20}
          animate={false}
          // animDelay={2000}
          // animateDuration={10000}
        />
      </div>
    </div>
  );
};

export default TemperatureCard;
