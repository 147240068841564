import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./styles.css";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import {
  GetSensorsQuery,
  GetSetupQuery,
  OnCreateSensorsSubscription,
  OnUpdateSensorsSubscription,
} from "../../API";
import * as subscriptions from "../../graphql/subscriptions";
import * as mutations from "../../graphql/mutations";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportPdf from "../../components/reports";
import BeatLoader from "react-spinners/BeatLoader";
// import LineGraph from "react-line-graph";

import { ProgressSpinner } from "primereact/progressspinner";

import swal from "sweetalert";
import * as queries from "../../graphql/queries";

import { downloadExcel } from "react-export-table-to-excel";
import { IoTime, IoTimeOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  PostList,
  PostEdit,
  PostCreate,
  PostIcon,
} from "../../components/post/index";
import axios from "axios";
import { InputText } from "primereact/inputtext";

import GaugeChart from "react-gauge-chart";
import GaugeComponent from "react-gauge-component";
import Swal from "sweetalert2";

import { Card } from "primereact/card";
import TemperatureCard from "../../components/temp_card";
import { Button, Divider, SelectField } from "@aws-amplify/ui-react";
import { Label, LabelGroup, TextInput } from "@primer/react";
import Pdf from "react-to-pdf";

// Change env

// test
// const s3_pdf_file_name = "test_temp_data";
// const sub_mail_to = "test_notifications";
// const site_name = "Test";

// Midrand
// const sub_mail_to = "tfg_temp_increase";
// const s3_pdf_file_name = "midrand_report";
// const site_name = "Midrand";

// Riverfield
// const sub_mail_to = "north_dc_temp_w arnings";
// const s3_pdf_file_name = "north_rand_report_report";
// const site_name = "Riverfields";

// Cape Towm
const s3_pdf_file_name = "capetown_report";
const sub_mail_to = "capetown_temp_increase";
const site_name = "Cape Town";

const Home = () => {
  const [ble_data, set_ble_data] = useState([] as any);
  const [open_set_up, set_open_set_up] = useState(false);
  const [labels_data, set_labels_data] = useState([] as any);
  const [loading, set_loading] = useState(false);
  const [download_modal, set_download_modal] = useState(false);
  const [tfg_data, set_tfg_data] = useState(new Object() as any);
  const [new_tfg_data, set_new_tfg_data] = useState(new Object() as any);
  const [new_ble_mac, set_new_ble_mac] = useState("");

  const [new_ble_name, set_new_ble_name] = useState("");
  const [sensors_data, set_sensors_data]: any = useState([]);
  //
  const [new_mail, set_new_mail] = useState("");
  const [curr_temps, set_curr_temps] = useState({} as any);
  const ref = useRef();

  // let should_add_labels = 0;
  useEffect(() => {
    get_data();

    // Sensors

    const sub_to_sensor_create = (
      API.graphql<GraphQLSubscription<OnCreateSensorsSubscription>>(
        graphqlOperation(subscriptions.onCreateSensors)
      ) as any
    ).subscribe({
      next: (payload: any) => {
        const new_data = payload.value.data?.onCreateSensors;
        if (new_data) {
          set_curr_temps((_: any) => {
            return {
              ..._,
              [new_data.mac]: new_data,
            };
          });
        }
      },
      error: (err: any) => console.log(err),
    });

    const sub_to_sensor_update = (
      API.graphql<GraphQLSubscription<OnUpdateSensorsSubscription>>(
        graphqlOperation(subscriptions.onUpdateSensors)
      ) as any
    ).subscribe({
      next: (payload: any) => {
        const new_data = payload.value.data?.onUpdateSensors;
        if (new_data) {
          set_curr_temps((_: any) => {
            return {
              ..._,
              [new_data.mac]: new_data,
            };
          });
          // set_sensors_data((_: any) => {
          //   return [..._.filter((sensor: any) => sensor.id !== new_data.id)];
          // });
        }
      },
      error: (err: any) => console.log(err),
    });

    return () => {
      sub_to_sensor_create.unsubscribe();
      sub_to_sensor_update.unsubscribe();
    };
  }, []);

  const sub_email = async () => {
    if (!new_mail) return;
    set_loading(true);

    await fetch(
      "https://uf8vbukiil.execute-api.us-east-1.amazonaws.com/default/sub_to_temp_mails",
      {
        keepalive: true,
        mode: "no-cors",
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_type: "subscription",
          email: new_mail,
          sub_to: sub_mail_to,
        }),
      }
    )
      .then(() => {
        set_new_mail("");
        set_loading(false);

        Swal.fire({
          title: "Email added",
          text: "Check your email for the verification email and confirm the subscription",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        set_loading(false);
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Error trying to subscribe email",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const data = (temp_data1: any, temp_data2: any) => {
    return {
      labels: labels_data,
      datasets: [
        {
          label: "Top",
          data: temp_data1,
          backgroundColor: ["#03fc90"],
          borderColor: "#03fc90",
          borderWidth: 5,
        },
        {
          label: "Bottom",
          data: temp_data2,
          backgroundColor: ["#0356fc"],
          borderColor: "#0356fc",
          borderWidth: 5,
        },
      ],
    };
  };

  const get_data = async () => {
    set_loading(true);
    try {
      const response: any = await API.graphql<GraphQLQuery<GetSetupQuery>>(
        graphqlOperation(queries.listSetups)
      );

      if (response?.data?.listSetups?.items?.length) {
        set_tfg_data(response?.data?.listSetups?.items[0]);
      }
      get_sensors();
    } catch (error) {
      console.log(error);
      set_loading(false);
    }
  };

  const get_sensors = async () => {
    try {
      const response: any = await API.graphql<GraphQLQuery<GetSensorsQuery>>(
        graphqlOperation(queries.listSensors)
      );
      if (response?.data?.listSensors) {
        set_sensors_data(response?.data?.listSensors?.items);
        // console.log(response?.data?.listSensors?.items);
      }
      set_loading(false);
    } catch (error) {
      set_loading(false);
      console.log(error);
    }
  };

  const sumbit_btn = async () => {
    if (!new_tfg_data.max_temperature)
      return swal({
        title: "Notification",
        text: "There is nothing to update",
        icon: "error",
      });

    const input = {
      id: tfg_data.id,
      ...new_tfg_data,
    };
    try {
      await API.graphql({
        query: mutations.updateSetup,
        variables: { input },
      });
    } catch (error) {
      console.log(error);
    }
    set_tfg_data({ ...tfg_data, ...new_tfg_data });
    set_new_tfg_data(new Object() as any);
    // Success

    swal({
      title: "Notification",
      text: "Data has been updated",
      icon: "success",
    });
    set_open_set_up(false);
  };

  const submit_sensor_name = async () => {
    if (!new_ble_name || !new_ble_mac)
      return swal({
        title: "Notification",
        text: "Enter Sensor Name",
        icon: "error",
      });

    // console.log(get_sensor_id_by_mac(new_ble_mac));

    // // return;
    set_loading(true);
    set_open_set_up(false);
    // console.log(new_ble_name);
    // console.log(new_ble_mac);
    try {
      await API.graphql({
        query: mutations.updateSensors,
        variables: {
          input: {
            mac: curr_temps[new_ble_mac].mac,
            name: new_ble_name,
          },
        },
      });

      swal({
        title: "Notification",
        text: "Data has been updated",
        icon: "success",
      });
      set_new_ble_mac("");
      set_new_ble_name("");
    } catch (error) {
      console.log(error);
    } finally {
      set_loading(false);
      set_open_set_up(true);
    }

    // Success
  };

  const download_execel = () =>
    downloadExcel({
      fileName: "TFG Report Data.xls",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header: [
          "Timestamp",
          "Mac",
          "Signal Strength",
          "Temperature (°C)",
          "Humidity",
          "Poistion",
        ],
        body: ble_data,
      },
    });

  const download_btn = async () => {
    set_loading(true);
    await fetch(
      "https://2r6wfxc3byvj55naqn42os7kza0tgztd.lambda-url.us-east-1.on.aws/" +
        s3_pdf_file_name
    )
      .then(async (_: any) => await _.json())
      .then((_) => {
        console.log(_);
        set_ble_data([
          ..._.map((temp: any) => {
            const ble_name = sensors_data.find(
              (ble: any) => ble.mac === temp.mac
            );
            return {
              ...temp,
              timestamp: new Date(temp.timestamp).toLocaleString(),
              position: ble_name?.name || "",
            };
          }),
        ]);
        console.log([
          ..._.map((temp: any) => {
            const ble_name = sensors_data.find(
              (ble: any) => ble.mac === temp.mac
            );
            return {
              ...temp,
              timestamp: new Date(temp.timestamp).toLocaleString(),
              position: ble_name?.name || "",
            };
          }),
        ]);
        set_download_modal(true);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        // always executed
        set_loading(false);
      });
  };

  return (
    <IonPage style={{ flex: 1, overflow: "auto" }}>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            position: "absolute",
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(8px)",
            width: "100vw",
            height: "100vh",
            top: 0,
            right: 0,
            overflow: "hidden",
          }}
        >
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      )}

      <IonHeader>
        <IonToolbar>
          <IonRow
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alingItem: "center",
            }}
          >
            <img width={50} height={50} src={require("./favicon.png")} />
            <IonTitle className="logo_text">
              TFG {site_name} Temperature Monitoring
            </IonTitle>

            <IonItem>
              {loading ? (
                <BeatLoader loading size={20} color="#333" />
              ) : (
                <IonButtons>
                  <IonButton onClick={() => download_btn()}>Download</IonButton>
                </IonButtons>
              )}
            </IonItem>

            <IonItem>
              <IonButtons>
                <IonButton onClick={() => set_open_set_up(true)}>
                  Set Up
                </IonButton>
              </IonButtons>
            </IonItem>
            {/*

            <IonText
              onClick={async () => {
                try {
                  await Auth.signOut();
                } catch (error) {
                  console.log("error signing out: ", error);
                }
              }}
              style={{
                color: "red",
                paddingRight: 30,
                cursor: "pointer",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
              }}
            >
              Sign Out
            </IonText>*/}
          </IonRow>
        </IonToolbar>
      </IonHeader>
      {/* ave */}
      {/* <div>
        <IonCard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonText
              style={{
                alignSelf: "flex-start  ",
                color:
                  Object.values(curr_temps)
                    .map((ble: any) => ble.temperature)
                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              <p>
                Top.
                <br /> Temperature Ave
              </p>
            </IonText>
            <IonText
              className="ave_temp_text"
              style={{
                lineHeight: 1,
                color:
                  Object.values(curr_temps)
                    .map((ble: any) => ble.temperature)

                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              {Object.keys(curr_temps).length
                ? (
                    Object.values(curr_temps)
                      .map((ble: any) => ble.temperature)
                      .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length
                  ).toFixed(1)
                : 0}
              °C
            </IonText>
          </div>
        </IonCard>
      </div> */}
      <div>
        <IonCard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonText
              style={{
                alignSelf: "flex-start  ",
                color:
                  Object.values(curr_temps)
                    .map((ble: any) => ble.temperature)
                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              <p>
                Ave.
                <br /> Temperature
              </p>
            </IonText>
            <IonText
              className="ave_temp_text"
              style={{
                lineHeight: 1,
                color:
                  Object.values(curr_temps)
                    .map((ble: any) => ble.temperature)
                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              {Object.keys(curr_temps).length
                ? (
                    Object.values(curr_temps)
                      .map((ble: any) => ble.temperature)
                      .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).length
                  ).toFixed(1)
                : 0}
              °C
            </IonText>
          </div>
        </IonCard>
      </div>
      {/* list */}

      {!!Object.keys(curr_temps)?.length && (
        <>
          <Card
            title="Current Temperature"
            className="flex p-3 justify-content-center"
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {Object.values(curr_temps).map((ble: any, i: any) => (
              <TemperatureCard
                key={i}
                ble={ble}
                color={
                  ble.temperature > tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0"
                }
                percent={
                  ble.temperature / tfg_data.max_temperature > 1
                    ? 1
                    : parseFloat(
                        (ble.temperature / tfg_data.max_temperature).toFixed(2)
                      )
                }
              />
            ))}
          </div>
        </>
      )}
      {!!sensors_data?.length &&
        Object.keys(curr_temps) &&
        sensors_data.filter((ble: any) =>
          Object.keys(curr_temps).includes(ble.mac)
        ) && (
          <>
            <Card
              title="Last Recorded Temperature"
              className="flex p-3 justify-content-center"
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                margin: "0 10px",
                marginBottom: "20px",
                justifyContent: "center",
              }}
            >
              {sensors_data
                .filter(
                  (ble: any) => !Object.keys(curr_temps).includes(ble.mac)
                )
                .map((ble: any, i: any) => (
                  <TemperatureCard
                    key={i}
                    ble={ble}
                    color={
                      ble.temperature > tfg_data.max_temperature
                        ? "#B7121F"
                        : "#22A7F0"
                    }
                    percent={
                      ble.temperature / tfg_data.max_temperature > 1
                        ? 1
                        : parseFloat(
                            (
                              ble.temperature / tfg_data.max_temperature
                            ).toFixed(2)
                          )
                    }
                  />
                ))}
            </div>
          </>
        )}
      {/* set up */}

      <IonModal
        animated={true}
        backdropDismiss={true}
        className="overflow-auto	"
        isOpen={open_set_up}
        onWillDismiss={() => set_open_set_up(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => set_open_set_up(false)}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Set Up</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ flex: 1, overflow: "auto" }} className="p-3">
          <div className="flex flex-column align-items-start		">
            <Label>Maximun Temperature</Label>
            <TextInput
              className="my-2"
              onChange={(e) =>
                set_new_tfg_data({
                  ...new_tfg_data,
                  max_temperature: e.target.value,
                })
              }
              value={new_tfg_data.max_temperature}
              placeholder={tfg_data.max_temperature + "°C"}
            />
            <Button loadingText="Updating" onClick={() => sumbit_btn()}>
              Update highest degrees
            </Button>
          </div>
          <IonCard className="p-1">
            <p className="text-color-secondary font-italic">Last reported at</p>
            <p className="font-semibold	"> {tfg_data.temp_last_report}</p>
          </IonCard>

          {/* <IonButtons>
            <IonButton size="large" onClick={() => sumbit_btn()}>
              
            </IonButton>
          </IonButtons> */}

          <div className="flex flex-column align-items-start		">
            <Label>Subscribe email</Label>
            <TextInput
              className="my-2"
              onChange={(e: any) => set_new_mail(e.target.value)}
              value={new_mail || ""}
              placeholder={"Email"}
            />
            <Button loadingText="Updating" onClick={() => sub_email()}>
              Subcribe for Notifications
            </Button>
          </div>

          <Divider className="my-3" />

          {!!Object.keys(curr_temps).length && (
            <>
              <SelectField
                label="Update Sensor names"
                descriptiveText="Select Sensor"
                placeholder="Select the sensor to name it"
                onChange={(e) => {
                  set_new_ble_mac(e.target.value);
                  set_new_ble_name("");
                  // set_selected_sensor()
                }}
              >
                {Object.keys(curr_temps).map((ble_id) => (
                  <option key={ble_id} value={ble_id}>
                    {ble_id + " "} {curr_temps[ble_id].name}
                  </option>
                ))}
              </SelectField>
              <br />
              {new_ble_mac && (
                <div className="flex flex-column align-items-start		">
                  <Label>Update Sensor names</Label>

                  <TextInput
                    className="my-2"
                    onChange={(e) => {
                      const text = e.target.value;
                      console.log(text);
                      set_new_ble_name(text);
                    }}
                    value={new_ble_name}
                    placeholder={"Sensor name"}
                  />
                  <Button
                    disabled={!new_ble_name}
                    loadingText="Updating"
                    onClick={() => submit_sensor_name()}
                  >
                    Update Sensor Name
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </IonModal>
      {/* Donwload */}

      <IonModal
        isOpen={download_modal}
        onWillDismiss={() => set_download_modal(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => set_download_modal(false)}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Download</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          {/*  <PDFDownloadLink
              onClick={() => {
                set_loading(true);
                set_download_modal(false);
              }}
              document={<ReportPdf ble_data={ble_data} />}
              fileName="report.pdf"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              {({ blob, url, loading, error }) => {
                console.log(blob);
                console.log(url);
                console.log(loading);
                if (error) {
                  console.log(error);
                  return;
                }
                return loading ? "Loading document..." : "Download PDF";
              }}
            </PDFDownloadLink> */}
          <IonButton
            onClick={() => {
              // set_loading(true);
              download_execel();
            }}
          >
            Download Excel
          </IonButton>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Home;
